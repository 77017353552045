import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_ISSUES,
  DELETE_ISSUE,
  EDIT_ISSUE,
  ISSUE_DATA,
  PROJECT_LIST,
  EXPORT_ISSUE,
  FLAG_ISSUE,
} from "services/endPoints";
import { projectSprintBacklogDetails } from "./backlogSlice";
import { getProjectId, getToken } from "utils/utils";
import toast from "react-hot-toast";
import { privateRequest } from "services/privateRequest";
import { getAllStageIssues } from "./activeSprintSlice";

const initialState = {
  loading: false, //Button Loader
  laoder: false,
  projectDetails: [],
  selectedIssue: {},
  issueDetails: {},
  projectsList: [],
};

export const getProjectsList = createAsyncThunk(
  "entity/getProjectsList",
  async (params) => {
    const res = await privateRequest.get(PROJECT_LIST, { params });
    return {
      data: res?.data?.data?.data || [],
    };
  }
);

export const getProjectDetails = createAsyncThunk(
  "entity/getProjectDetails",
  async (id, {signal}) => {
    const res = await privateRequest.get(`/api/issues/${id}/pre-details`, {signal});
    return {
      data: res?.data?.data || [],
    };
  }
);

export const createIssue = createAsyncThunk(
  "entity/createIssue",
  async (params, { dispatch }) => {
    const res = await privateRequest.post(CREATE_ISSUES, params);
    toast.success(res.data.message);
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
  }
);

export const getIssueData = createAsyncThunk(
  "entity/getIssueData",
  async ({ id, type = null,initLoader=false}, {signal}) => {
    const res = await privateRequest.get(ISSUE_DATA + `/${id}`, {signal});
    return {
      data: res?.data?.data || [],
      details: type,
      initLoader
    };
  }
);

export const editIssue = createAsyncThunk(
  "entity/editIssue",
  async ({ id, formData }, { dispatch }) => {
    const res = await privateRequest.put(EDIT_ISSUE + `/${id}`, formData);
    toast.success(res?.data?.message);
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
  }
);

export const editSingleFieldIssue = createAsyncThunk(
  "entity/editIssue",
  async ({ id, formData, filterParams, params, type }, { dispatch }) => {

    try {
      await privateRequest.patch(EDIT_ISSUE + `/${id}`, formData);
    } catch (error) {
      throw error;
    }
    if (type === "sprint") {
      await dispatch(
        getAllStageIssues({
          data: {
            projectId: localStorage.getItem("projectId"),
          },
          params: params || {},
        })
      );
    } else if (type === "backlog") {
      await dispatch(
        projectSprintBacklogDetails({
          data: { id: getProjectId() },
          params: filterParams,
        })
      );
    }
  }
);

export const deleteIssue = createAsyncThunk(
  "entity/deleteIssue",
  async (id, { dispatch }) => {
    const res = await privateRequest.delete(DELETE_ISSUE + `/${id}`);
    toast.success(res?.data?.message);
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
    dispatch(
      getAllStageIssues({
        data: {
          projectId: localStorage.getItem("projectId"),
        },
        // params: params?.filterParams,
      })
    );
  }
);

export const flagIssue = createAsyncThunk(
  "entity/flagIssue",
  async ({issueId, flagData}, {dispatch, rejectWithValue}) => {
    try {
      const res = await privateRequest.post(`${FLAG_ISSUE}/${issueId}/flag`, flagData);
      dispatch(getIssueData({ id: issueId, type: "details" }));
      await dispatch(
        getAllStageIssues({
          data: {
            projectId: localStorage.getItem("projectId"),
          },
        })
      );
      toast.success(res?.data?.message || "Flag added");
    
  } catch (error) {
    rejectWithValue(error);
    toast.error(error?.data?.message || "Some thing went wrong");
  }
  }
);

export const exportIssueById = createAsyncThunk(
  "entity/exportIssueById",
  async (params) => {
    try {
      const res = await privateRequest.get(
        `${EXPORT_ISSUE}/${params.id}/export/word`, {
        responseType: 'blob',
      }
      );
      const contentDisposition = res.headers['content-disposition'];
      const fileName = contentDisposition ? contentDisposition?.split('filename=')[1] : "issue.docx";
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      toast.success(res?.data?.message || "File export successful!");
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

const issueSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedIssue: (state, action) => {
      state.selectedIssue = action.payload;
    },
    setIssueDetailsEmpty: (state, action) => {
      state.issueDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsList.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getProjectsList.fulfilled, (state, action) => {
        state.projectsList = action.payload.data;
        state.loader = false;
      })
      .addCase(getProjectsList.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getProjectDetails.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        state.projectDetails = action.payload.data;
        state.loader = false;
      })
      .addCase(getProjectDetails.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getIssueData.pending, (state, action) => {
        if (action.meta?.arg?.initLoader)
          state.loader = true;
      })
      .addCase(getIssueData.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.details) {
          state.issueDetails = action?.payload?.data;
        } else {
          state.selectedIssue = action.payload.data;
        }
      })
      .addCase(getIssueData.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(exportIssueById.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(exportIssueById.fulfilled, (state, action) => {
        state.loader = false;
      })
      .addCase(exportIssueById.rejected, (state, action) => {
        state.loader = false;
      });
  },
});

export default issueSlice.reducer;
export const { setLoading, setSelectedIssue, setIssueDetailsEmpty } =
  issueSlice.actions;
