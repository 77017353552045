// import logo from "./logo.svg";
import { router } from "router";
import styles from "./App.module.scss";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import ErrorBoundary from "ErrorBoundary";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a 3-second delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timeout on unmount
  }, []);
  return (
    <div className={styles.app}>
      {/* <ErrorBoundary> */}
        <Suspense fallback={<Loader />}>
          <RouterProvider router={router} />
        </Suspense>
      {/* </ErrorBoundary> */}
    </div>
  );
}

export default App;
